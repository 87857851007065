<template>
  <div class="w-full">
    <vs-row vs-type="flex" vs-w="12">
      <vs-col vs-w="4">
        <div class="vs-con-loading__container">
          <vx-card card-background="#021a33">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="3"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div class="p-1">
                  <h4 style="color: white">Colaboradores</h4>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <div v-if="!colaboradores.length > 0">
            <div class="con-colors mt-10 mb-20">
              <ul>
                <span
                  class="px-3 pt-2"
                  style="color: grey !important; font-weight: 1000 !important"
                  ><vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon
                ></span>
              </ul>
            </div>
          </div>
          <VuePerfectScrollbar
            class="scroll-metas"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <vs-list v-for="(data, idx) in colaboradores" v-bind:key="idx">
              <vx-card
                :class="
                  colaborador != data.id ? 'list-item' : 'active-list-item'
                "
                @click="(colaborador = data.id), getMetasColaborador()"
              >
                <template slot="no-body">
                  <vs-row
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                  >
                    <vs-col
                      class="px-2 mt-1"
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                    >
                      <vs-list-item
                        :title="data.nome"
                        :subtitle="data.perfil"
                      ></vs-list-item>
                    </vs-col>
                  </vs-row>
                </template>
              </vx-card>
            </vs-list>
          </VuePerfectScrollbar>
        </div>
        <div v-if="!colaboradores.length > 0">
          <div class="con-colors my-5">
            <ul>
              <li class="danger-box">
                <span class="p-2" style="color: white !important"
                  >Nenhum colaborador disponivel</span
                >
              </li>
            </ul>
          </div>
        </div>
      </vs-col>
      <vs-col vs-w="8" class="px-2">
        <div id="meta_card" class="vs-con-loading__container">
          <vx-card card-background="#000a12">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="3"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div class="p-1">
                  <h4 style="color: white">Metas</h4>
                </div>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-w="9"
                vs-justify="flex-end"
                vs-align="center"
              >
                <vs-button
                  size="small"
                  color="success"
                  @click="associarMetasColaborador()"
                  type="relief"
                  :disabled="
                    !$atividades.permissoes.includes(14) || colaborador == null
                  "
                  >Associar metas</vs-button
                >
              </vs-col>
            </vs-row>
          </vx-card>
          <vx-card>
            <div class="w-full" :key="reRender">
              <vs-row
                vs-w="12"
                vs-type="flex"
                vs-justify="space-between"
                class="w-full"
              >
                <vs-col
                  vs-w="6"
                  vs-type="flex"
                  vs-justify="space-around"
                  vs-align="flex-start"
                  v-for="(data, index) in metas"
                  :key="index"
                >
                  <div class="flex mb-1 mt-1">
                    <vs-input
                      type="number"
                      class="mt-2"
                      color="success"
                      :disabled="colaborador == null"
                      :label="data.nome"
                      v-model="metas[index].valor"
                      min="0"
                      name="areaQtd"
                      @change="reRender = reRender + 1"
                    />
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      colaboradores: [],
      colaborador: null,
      metas: [],
      metasColaborador: [],
      reRender: 1,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
        height: 1000,
      },
    };
  },
  methods: {
    async getColaboradores() {
      try {
        this.colaboradores = await this.$http.get(`getColaboradorPerfil`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getMetas() {
      try {
        this.metas = await this.$http.get(`metas`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getMetasColaborador() {
      await this.$vs.loading({
        container: "#meta_card",
        scale: 0.6,
      });
      try {
        this.metas.map(function (x) {
          x.valor = 0;
          return x;
        });
        let res = await this.$http.get(`meta_colaborador/` + this.colaborador);
        res.forEach((data) => {
          const index = this.metas.map((x) => x.id).indexOf(data.pivot.id_meta);
          this.metas[index].valor = data.pivot.valor;
        });
        this.reRender = this.reRender + 1;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#meta_card > .con-vs-loading");
      }
    },
    async associarMetasColaborador() {
      await this.$vs.loading({ container: "#meta_card", scale: 0.6 });
      const metas = [];
      for (let i = 0; i < this.metas.length; i++) {
        if (this.metas[i].valor > 0) {
          metas.push(this.metas[i]);
        }
      }
      try {
        await this.$http.post("meta_colaborador", {
          id: this.colaborador,
          metas: metas,
        });
        //await this.getMetasColaborador();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#meta_card > .con-vs-loading");
      }
    },
  },
  async mounted() {
    this.$vs.loading();
    await this.getColaboradores();
    await this.getMetas();
    this.$vs.loading.close();
  },
  components: {
    ...components,
  },
};
</script>
<style lang="scss" scoped>
.item-disabled {
  background-color: #f0f0f0;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  cursor: not-allowed;
  h4,
  h6 {
    color: #7c7c7c;
  }
}
.list-item {
  color: black;
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}
.list-item:hover {
  color: black;
  transition: 0.1s;
  background: #eeeeee;
  transform: translateY(-3px);
}
.active-list-item {
  color: black;
  background-color: #00bba2;
  transition: background 0.1s ease-in-out;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
}

.scroll-metas {
  max-height: 70vh !important;
}
</style>
